@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

$black-color: #313249;
$purple-color: #666dff;
$medium-purple-color: #585ea2;
$light-purple-color: #e8efff;
$dark-grey-color: #999aaf;
$grey-color: #f0f0f0;
$light-grey-color: #f5f7fb;
$white-color: #ffffff;
$aqua-blue-color: #00cbff;

$background-color: $light-grey-color;
$title-color: $black-color;
$description-color: $dark-grey-color;
$primary-color: $purple-color;

$primary-gradient: linear-gradient(90deg, $purple-color, $aqua-blue-color);
$default-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

@mixin transition {
  transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -webkit-transition: 0.25s ease-in-out;
}

@mixin title {
  font-size: 30px;
  font-weight: 700;
  color: $title-color;
  max-width: 650px;
  text-align: center;
  strong {
    font-weight: 700;
    color: $primary-color;
  }
  @media (min-width: 600px) {
    font-size: 36px;
  }
}

@mixin description {
  font-size: 15px;
  font-weight: 500;
  color: $description-color;
  max-width: 650px;
  text-align: center;
  line-height: 28px;
  strong {
    font-weight: 700;
    color: $primary-color;
  }
  @media (min-width: 600px) {
    font-size: 16px;
  }
}

* {
  box-sizing: border-box;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.ios-overscroll {
  position: fixed;
  z-index: -1;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  background: $background-color;
}

html,
body {
  padding: 0;
  margin: 0;
  background: $background-color;
  color: $title-color;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

textarea,
select,
input,
button {
  outline: none;
}

a {
  text-decoration: none;
}

.caption {
  font-size: 15px;
  font-weight: 500;
  color: $description-color;
  max-width: 650px;
  text-align: center;
  strong {
    font-weight: 700;
  }
}

.top--toolbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  background: $light-grey-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border-bottom: 1px solid $grey-color;
  padding: 0px 20px;
  z-index: 1000;
  .left,
  .center,
  .right {
    display: flex;
  }
  .left,
  .right {
    height: 20px;
    width: 20px;
  }
}

.container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.content--inset {
    padding-top: 70px;
  }
  &.content--inset--bottom {
    padding-bottom: 100px;
  }
  .bottom-toolbar {
    pointer-events: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 20px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: end;
    background: linear-gradient(
      0deg,
      rgba(245, 247, 251, 1) 0%,
      rgba(245, 247, 251, 0) 100%
    );
  }
}

.chip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $black-color;
  border-radius: 50px;
  padding: 8px 14px;
  color: $white-color;
  font-size: 16px;
  font-weight: 700;
}

.button--group {
  .button {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.button {
  display: inline-flex;
  background: $primary-gradient;
  border: 0;
  border-radius: 50px;
  padding: 18px 36px;
  color: $white-color;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  cursor: pointer;
  user-select: none;
  @include transition;

  &:hover {
    box-shadow: $default-shadow;
  }

  &:active {
    transform: scale(0.98);
  }

  &.disabled {
    background: $dark-grey-color;
    box-shadow: none !important;
    transform: none !important;
  }

  &.secondary {
    background: $black-color;
  }

  &.full-width {
    width: 100%;
  }

  @media (max-width: 600px) {
    width: 100%;
    max-width: 350px;
  }
}

.options--selector {
  .option {
    @include transition;
    background: $white-color;
    color: $black-color;
    padding: 12px 20px;
    border-radius: 16px;
    font-weight: 700;
    font-size: 24px;
    border: 2px solid $grey-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 10px;
    min-width: 300px;
    > * {
      @include transition;
    }
    &.selected {
      box-shadow: $default-shadow;
    }
    &:active {
      transform: scale(0.98);
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

input {
  @include transition;
  color: $black-color;
  padding: 12px 20px;
  border-radius: 16px;
  font-weight: 700;
  font-size: 24px;
  border: 2px solid $grey-color;
  &:focus {
    border: 2px solid $primary-color;
    box-shadow: $default-shadow;
  }
  &::placeholder {
    color: $dark-grey-color;
  }
}

.section {
  position: relative;
  background: $background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  content-visibility: auto;
  &.full-height {
    justify-content: center;
    min-height: calc(100vh - 140px);
  }
  &.section--hero {
    justify-content: center;
    min-height: 100vh;
    .hero--overlay {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .hero--background {
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .section--container {
      z-index: 2;
    }
    .section--title {
      @include title;
      color: #fff;
      font-size: 30px;
      @media (min-width: 600px) {
        font-size: 36px;
      }
    }
    .section--description {
      @include description;
      color: #fff;
    }
    .section--hero--image {
      max-height: 425px;
      max-width: 90%;
    }
    .section--bullets {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      .section--bullet {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        .section--bullet--icon {
          margin-right: 10px;
        }
        .section--bullet--text {
          font-size: 18px;
          font-weight: 700;
          color: $white-color;
        }
      }
    }
    .section--hero--price {
      display: flex;
      flex-direction: column;
      align-items: center;
      .prices {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        .price {
          line-height: 30px;
          font-size: 30px;
          font-weight: 700;
          color: $white-color;
        }
        .comparable {
          margin-left: 5px;
          line-height: 18px;
          font-size: 18px;
          font-weight: 700;
          color: $white-color;
          text-decoration: line-through;
        }
      }
      .cost-per {
        line-height: 12px;
        font-size: 12px;
        font-weight: 600;
        color: $white-color;
        font-style: italic;
      }
    }
  }

  &.section--footer {
    background: $black-color;
    .footer--link {
      font-weight: 600;
      background: $purple-color;
      color: $white-color;
      padding: 8px 15px;
      text-align: center;
      margin-bottom: 10px;
      border-radius: 50px;
      font-size: 16px;
      cursor: pointer;
      @include transition;

      &:hover {
        box-shadow: $default-shadow;
      }

      &:active {
        transform: scale(0.98);
      }
      &:last-child {
        margin-bottom: 20px;
      }
    }
  }

  &.section--secondary {
    background: $light-purple-color;
  }

  &.section--footer {
    background: $black-color;
  }

  .section--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    padding: 50px 10px;
  }

  .section--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    & > * {
      margin: 0 0 30px;
      &:last-child {
        margin: 0px;
      }
    }
  }

  .section--header {
    text-align: center;
    margin: 0 0 30px;
    max-width: 400px;
    .section--image {
      height: 125px;
      margin-bottom: 20px;
      @media (min-width: 600px) {
        height: 150px;
      }
    }
    .section--title {
      @include title;
      margin-bottom: 10px;
    }
    .section--description {
      @include description;
    }
  }
}

.section--how-it-works {
  .section--content {
    .step--items {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .step--item {
        width: 100%;
        max-width: 350px;
        text-align: center;
        &:last-child {
          .step--item--arrow {
            display: none;
          }
        }
        .step--item--step {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: $light-purple-color;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto 10px;
          font-size: 24px;
          color: $title-color;
          font-weight: 700;
        }
        .step--item--title {
          @include title;
          font-size: 24px;
          color: $title-color;
          margin: 0 0 10px;
        }
        .step--item--description {
          @include description;
          color: $description-color;
          margin: 0 0 10px;
        }
        .step--item--arrow {
          height: 20px;
          margin-bottom: 25px;
          margin-top: 15px;
        }
      }
    }
  }
}

.section--styles {
  .section--content {
    .carousel--item {
      position: relative;
      overflow: hidden;
      border-radius: 10%;
      width: calc(100vh - 2rem);
      max-width: 20rem;
      aspect-ratio: 1 / 1;
      backdrop-filter: blur(0);
    }

    .carousel--item > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }

    .carousel--item > .label {
      position: absolute;
      font-size: 1rem;
      text-align: center;
      color: #fff;
      font-weight: 700;
      bottom: 0.5rem;
      right: 0.5rem;
      backdrop-filter: blur(10px);
      background-color: rgba(256, 256, 256, 0.1);
      font-size: 0.75rem;
      padding: 0.25rem 0.5rem;
      border-radius: 3rem;
      overflow: none;
    }
  }
}

.choose-styles-grid {
  display: grid;
  place-items: center;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, 110px);
  width: 100%;
  justify-content: center;
  .style-item {
    width: 110px;
    height: 145px;
    overflow: hidden;
    border-radius: 12px;
    backdrop-filter: blur(0);
    border: 4px solid transparent;
    @include transition;
    background: $white-color;
    display: flex;
    flex-direction: column;
    box-shadow: $default-shadow;
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
      object-fit: cover;
      overflow: hidden;
      border-radius: 8px;
    }
    .label {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      color: $title-color;
      font-weight: 700;
      overflow: none;
      max-lines: 2;
      text-overflow: ellipsis;
    }
    &.selected {
      border: 4px solid #676dff;
      box-shadow: $default-shadow;
    }
    &:hover {
      box-shadow: $default-shadow;
    }
    &:active {
      transform: scale(0.98);
    }
    &.disabled {
      opacity: 0.5;
      box-shadow: none;
      transform: scale(0.98);
    }
  }
}

.infinite-scroll-component__outerdiv {
  width: 100%;
  display: flex;
  justify-content: center;
}

.order--results {
  display: grid;
  place-items: center;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, max(350px));
  justify-content: center;
  width: 100%;
  .order--result {
    background: $white-color;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: $default-shadow;
    padding: 15px 15px;
    display: inline-flex;
    flex-direction: column;
    .order--result--content {
      position: relative;
      overflow: hidden;
      border-radius: 12px;
      aspect-ratio: 1 / 1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .label {
        position: absolute;
        font-size: 14px;
        text-align: center;
        color: #fff;
        font-weight: 700;
        top: 15px;
        right: 15px;
        backdrop-filter: blur(10px);
        background-color: rgba(256, 256, 256, 0.2);
        padding: 5px 10px;
        border-radius: 30px;
        overflow: none;
        box-shadow: $default-shadow;
      }
    }
  }
}

.upload--requirement {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 8px 8px;
  border-radius: 16px;
  background: $white-color;
  font-size: 22px;
  color: $black-color;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
  max-width: 500px;
  &:last-child {
    margin-bottom: 0px;
  }
  img {
    height: 35px;
    margin-right: 10px;
  }
}

.style--grid--categories {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, auto);
  .style--grid--category {
    font-size: 16px;
    color: $white-color;
    font-weight: 700;
    text-align: center;
    background: $black-color;
    border-radius: 50px;
    padding: 5px 12px;
    cursor: pointer;
    @include transition;
    &.selected {
      box-shadow: $default-shadow;
      background: $white-color;
      color: $primary-color;
    }
    &:hover {
      box-shadow: $default-shadow;
    }

    &:active {
      transform: scale(0.98);
    }
  }
  .style--grid--category-2 {
    background: $light-grey-color;
    height: 150px;
    width: 150px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    @include transition;
    cursor: pointer;
    box-shadow: $default-shadow;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      @include transition;
    }
    &.selected {
      background: $white-color;
      color: $primary-color;
      border: 4px solid #676dff;
    }
    &:active {
      transform: scale(0.98);
    }
  }
}

.style--grid {
  display: grid;
  place-items: center;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, 300px);
  width: 100%;
  justify-content: center;
  .style--grid--item {
    background: $light-grey-color;
    height: 300px;
    width: 300px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    transform: translateZ(0);
    @include transition;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      @include transition;
    }
    .style--grid--item--label {
      font-size: 16px;
      overflow: hidden;
      max-lines: 1;
      max-width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $primary-color;
      font-weight: 700;
      text-align: center;
      position: absolute;
      bottom: 10px;
      right: 10px;
      background: $white-color;
      border-radius: 50px;
      padding: 4px 10px;
    }
    &:hover {
      box-shadow: $default-shadow;
      img {
        transform: scale(1.1);
      }
    }
  }
}

.faq--item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 15px;
  border-radius: 20px;
  background: $white-color;
  box-shadow: $default-shadow;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0px;
  }
  .faq--question {
    font-size: 24px;
    color: $black-color;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
  }
  .faq--answer {
    font-size: 18px;
    color: $dark-grey-color;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
    strong {
      color: $primary-color;
      font-weight: 600;
    }
  }
}
